<template>
  <div class="main-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
import "./assets/styles/styles.scss";
import router from "./router/index";

export default {
  name: "quiz-app",
  mounted() {
    window.onload = function () {
      router.push({ path: "/" });
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

