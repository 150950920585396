const state = {
	questions: [
		{
			question: `Згадайте перший контейнер «Просвіта-лаунж».
			А тепер скажіть, яку з цих світлин зробив Кароль Дівольд?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: true,
					isChecked: null,
					answer: 'Фото 1',
					imgUrl: 'assets/q1-img1.jpg',
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Фото 2',
					imgUrl: 'assets/q1-img2.jpg',
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Обидві',
					imgUrl: 'assets/q1-img3.jpg',
				},
			]
		},
		{
			question: `У якому році назва «Україна» вперше з’явилася на європейській карті?`,
			displayInCol: false,
			points: 1,
			prompt: 'Пiдказка: це карта Радзивiлла.',
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: '1662 рік',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: '1650 рік',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: '1660 рік',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: '1613 рік',
					imgUrl: null,
				}
			]
		},
		{
			question: `Інтерактивні карти бачили? Бачили!
			Тоді відповідайте, як ніколи не називалося
			місто Дніпро?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: 'Новий Кодак',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Новоросійськ',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: 'Ворошиловград',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Катеринослав',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Дніпропетровськ',
					imgUrl: null,
				}
			]
		},
		{
			question: `У блоці виставки «Смакувати» є чотири роботи Казимира Малевича. Дві з них присвячені становищу українських селян під час колективізації та Голодомору 1932–1933 років. У якій парі представлено саме ці дві картини?`,
			points: 1,
			displayInCol: false,
			fourImages: true,
			prompt: null,
			answers: [
				{
					isRight: true,
					isChecked: null,
					answer: 'Варіант 1',
					imgUrl: 'assets/q4-img1.1.jpg',
					imgUrl2: 'assets/q4-img1.2.jpeg',
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Вариант 2',
					imgUrl: 'assets/q4-img2.1.jpeg',
					imgUrl2: 'assets/q4-img2.2.jpeg',
				}
			]
		},
		{
			question: `А згадаєте, на якій з картин зображені вже зібрані плоди української землі?`,
			points: 1,
			displayInCol: true,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: '«Блакитні Карпати», В. Патик',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: '«Спас», О. Прахова',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: '«Дощ» із серії «Кримські дороги», О. Придувалова',
					imgUrl: null,
				}
			]
		},
		{
			question: `Перед вами картина «Жінки біля яблуні» Тимофія Бойчука та дещо змінена її копія. Знайдете оригінальну роботу художника?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: true,
					isChecked: null,
					answer: 'Зображення 1',
					imgUrl: 'assets/q6-img1.png',
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Зображення 2',
					imgUrl: 'assets/q6-img2.png',
				},
			]
		},
		{
			question: `Звертаємося до ваших рецепторів нюху:
			петрикор — це аромат чого?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: 'Вологого піску',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Ранкової трави',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: 'Землі після дощу',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Степу перед грозою',
					imgUrl: null,
				}
			]
		},
		{
			question: `Підказка З попереднього запитання: аромат землі після дощу — це петрикор. А як тоді називається речовина, що формує цей запах?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: 'Калон',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: 'Геосмін',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Циклацет',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Афермат',
					imgUrl: null,
				}
			]
		},
		{
			question: `Ну що, переходимо до блоку «Відчувати»? Оберіть, які знаряддя праці використовували косарі.`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: 'Бабка з молотком',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Мантачка',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Кушка',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: 'Всі варіанти правильні',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Правильних варіантів немає',
					imgUrl: null,
				}
			]
		},
		{
			question: `А для чого використовували суковатку, пам’ятаєте?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: true,
					isChecked: null,
					answer: 'Загортання зерна в землю',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'В’язання снопів',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Гостріння коси',
					imgUrl: null,
				}
			]
		},
		{
			question: `Як у вас із візуальною пам'яттю? Сподіваємося, що все добре, і пропонуємо обрати фото, на якому зображене рало.`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: '',
					imgUrl: 'assets/q11-img2.png',
				},
				{
					isRight: true,
					isChecked: null,
					answer: '',
					imgUrl: 'assets/q11-img1.png',
				},
				{
					isRight: false,
					isChecked: null,
					answer: '',
					imgUrl: 'assets/q11-img3.png',
				}
			]
		},
		{
			question: `Це вже останнє запитання, до блоку «Чути». Яке українське озеро найглибше?`,
			displayInCol: false,
			points: 1,
			prompt: null,
			answers: [
				{
					isRight: false,
					isChecked: null,
					answer: 'Синевир',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Ялпуг',
					imgUrl: null,
				},
				{
					isRight: false,
					isChecked: null,
					answer: 'Сасик-Сиваш',
					imgUrl: null,
				},
				{
					isRight: true,
					isChecked: null,
					answer: 'Світязь',
					imgUrl: null,
				},
			]
		}
	],
	userPoints: 0,
}

const getters = {
	questionById: (state) => (id) => state.questions[id],
	questionsLength: (state) => state.questions.length,
	userPoints: (state) => state.userPoints,
	correctAnswersArr: (state) => state.questions.map(question => question.answers.map(answer => answer.isRight).filter(isRight => isRight === true))
}

const mutations = {
	SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
	SET_USERPOINTS(state, payload) {
		state.userPoints = payload;
	},
	updateUserPoints(state, payload) {
		state.userPoints = payload;
	}
}

const actions = {
	updateUserPoints(ctx, payload) {
		ctx.commit('SET_USERPOINTS', payload);
	}
}

export default {
  state,
  getters,
  mutations,
  actions
}
